import { EngineEventStatus } from '@/domains/detect/models/enums'
import { DateTime } from 'luxon'

export class EngineEventDataPoint {
    private _timestamp: string
    private _values: { [key: string]: unknown }
    private _status: EngineEventStatus
    private _level: number
    private _start: DateTime | null
    private _end: DateTime | null

    constructor(
        timestamp: string,
        values: { [p: string]: unknown },
        status: EngineEventStatus,
        level: number,
        start: DateTime | null,
        end: DateTime | null
    ) {
        this._timestamp = timestamp
        this._values = values
        this._status = status
        this._level = level
        this._start = start
        this._end = end
    }

    get timestamp(): string {
        return this._timestamp
    }

    get values(): { [p: string]: unknown } {
        return this._values
    }

    get status(): EngineEventStatus {
        return this._status
    }

    get level(): number {
        return this._level
    }

    get start(): DateTime | null {
        return this._start
    }

    get end(): DateTime | null {
        return this._end
    }
}
