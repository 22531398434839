import { IService } from './IService'

export class ServiceLocator {
    /**
     * Registers and returns service instances based on a string key.
     * Inspired from : https://paloma.one/de/blog/simple-service-locator-with-typescript
     */

    private services: Map<string, IService> = new Map()

    add<T extends IService>(serviceId: string, instance: T): void {
        if (serviceId === null || serviceId.length === 0) {
            throw 'ServiceLocator.add.invalidServiceId : should be a non-blank string'
        }
        if (this.hasService(serviceId)) {
            throw `ServiceLocator.add.invalidServiceId : there is an already registered service for id ${serviceId}`
        }
        this.services.set(serviceId, instance)
    }

    get<T extends IService>(serviceId: string): T {
        if (!this.hasService(serviceId)) {
            throw `ServiceLocator.get.invalidServiceId : no registered service for id ${serviceId}`
        }
        return this.services.get(serviceId) as T
    }

    private hasService(serviceId: string): boolean {
        return this.services.has(serviceId)
    }
}
