export const noAlertColor = '#90d083'
export const alert1Color = '#ffffb2'
export const alert2Color = '#fecc5c'
export const alert3Color = '#fd8d3c'
export const alert4Color = '#f03b20'
export const alert5Color = '#bd0026'
export const noDataColor = '#2f80ed'

export const defaultTextColor = '#000000'

export const badgeColorScheme: Record<string, string | null> = {
    [noAlertColor]: defaultTextColor,
    [alert1Color]: defaultTextColor,
    [alert2Color]: defaultTextColor,
    [alert3Color]: defaultTextColor,
    [alert4Color]: '#FFFFFF',
    [alert5Color]: '#FFFFFF',
    [noDataColor]: '#FFFFFF'
}
