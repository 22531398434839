import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { Language } from '@/domains/common/models'

import { BaseService } from '../BaseService'
import { ServiceLocator } from '../ServiceLocator'
import { ITranslationService } from './ITranslationService'
import { getEnv } from "@/utils/env"
import English from './mock/english.json'
import French from './mock/french.json'

Vue.use(VueI18n)

/* eslint-disable @typescript-eslint/no-explicit-any */
export class MockTranslationService extends BaseService implements ITranslationService {
    protected readonly i18n: VueI18n = new VueI18n({
        fallbackLocale: getEnv("VUE_APP_DEFAULT_LANGUAGE")
    })

    protected languages: Map<string, any> = new Map()

    constructor(serviceLocator: ServiceLocator) {
        super(serviceLocator)
        this.languages.set('en', English)
        this.languages.set('fr-be', French)
        this.languages.set('fr-fr', French)
    }

    public getVuePlugin(): VueI18n {
        return this.i18n
    }

    public async getLanguages(): Promise<Language[]> {
        return [
            new Language('en', 'English'),
            new Language('fr-be', 'Français (be)'),
            new Language('fr-fr', 'Français (fr)'),
            new Language('nl', 'Nederlands')
        ]
    }

    public async useLanguage(code: string): Promise<void> {
        if (this.i18n.locale === code) {
            return Promise.resolve()
        }

        if (this.languages.has(code)) {
            this.configure(code)
            return Promise.resolve()
        }
    }

    public translate(key: string): string {
        return this.i18n.t(key).toString()
    }

    protected configure(code: string): void {
        this.i18n.setLocaleMessage(code, this.languages.get(code))
        this.i18n.locale = code
        document.querySelector('html')?.setAttribute('lang', code)
    }
}
