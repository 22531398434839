export class EngineConfigurationSimple {
    private _id: string | null
    private _providerType: string
    private _name: string
    private _active: boolean

    constructor(id: string | null, providerType: string, name: string, active: boolean) {
        this._id = id
        this._providerType = providerType
        this._name = name
        this._active = active
    }

    public get id(): string | null {
        return this._id
    }

    public get providerType(): string {
        return this._providerType
    }

    public get name(): string {
        return this._name
    }

    public get active(): boolean {
        return this._active
    }

    public set active(value: boolean) {
        this._active = value
    }
}
