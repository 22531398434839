import { RouteConfig } from 'vue-router'

const Auth = (): Promise<typeof import('*.vue')> => import(/* webpackChunkName: "auth" */ '../containers/Auth.vue')
const Login = (): Promise<typeof import('*.vue')> => import(/* webpackChunkName: "auth" */ '../containers/Login.vue')
const ExternalAuth = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "auth" */ '../containers/ExternalAuth.vue')
const PasswordForgotten = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "auth" */ '../containers/PasswordForgotten.vue')
const ResetPassword = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "auth" */ '../containers/ResetPassword.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'login',
        name: 'Auth',
        component: Login,
        props: true
    },
    {
        path: 'password-forgotten',
        name: 'PasswordForgotten',
        component: PasswordForgotten
    },
    {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        props: (route) => ({
            token: route.query.token
        })
    },
    {
        path: 'external',
        name: 'ExternalAuth',
        component: ExternalAuth,
        props: true
    },
    {
        path: '',
        redirect: { name: 'Auth' }
    }
]

export const container = Auth
