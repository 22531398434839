import { ISettingsAPI, SettingsAPI } from '@/api/settings'

import { BaseService } from '../BaseService'
import { ISettingsService } from './ISettingsService'
import { AppVersion } from '../../models'

export class SettingsService extends BaseService implements ISettingsService {
    private readonly api: ISettingsAPI = new SettingsAPI()

    public async getVersion(): Promise<AppVersion> {
        const dto = await this.api.retrieveVersion()
        if (!dto) {
            throw new Error('version.errors.not_found')
        }

        return new AppVersion(dto.version, dto.build)
    }
}
