export class Language {
    private _code: string
    private _name: string

    constructor(code: string, name: string) {
        this._code = code
        this._name = name
    }

    public get code(): string {
        return this._code
    }

    public get name(): string {
        return this._name
    }
}