import { ServerError } from './ServerError'

export class ApiError extends Error {
    constructor(message?: string) {
        super(message || '')

        Object.setPrototypeOf(this, ApiError.prototype)
    }

    public static from(error?: ServerError): ApiError {
        return new ApiError(error?.error)
    }
}
