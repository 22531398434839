import { RoleEnum } from './enums'
import { Organization } from './Organization'

export class Scope {
    private _organization: Organization
    private _role: RoleEnum

    constructor(organization: Organization, role: RoleEnum) {
        this._organization = organization
        this._role = role
    }

    public get organization(): Organization {
        return this._organization
    }

    public get role(): RoleEnum {
        return this._role
    }
}