import { OrganizationDTO } from './OrganizationDTO'
import { RoleDTO, ScopeDTO } from './ScopeDTO'
import { UserOrganizationRaw, UserRoleRaw, UserScopeRaw } from './UserScopeRaw'

export abstract class ScopeDTOFactory {
    private static roleRawToDTOMappings: Record<UserRoleRaw, RoleDTO> = {
        'master-administrator': RoleDTO.MASTER_ADMINISTRATOR,
        'organization-administrator': RoleDTO.ORGANIZATION_ADMINISTRATOR,
        manager: RoleDTO.MANAGER,
        operator: RoleDTO.OPERATOR
    }

    static fromRaw(scopeRaw: UserScopeRaw): ScopeDTO {
        return new ScopeDTO(
            ScopeDTOFactory.fromOrganizationRaw(scopeRaw.organization),
            ScopeDTOFactory.fromRoleRaw(scopeRaw.role)
        )
    }

    static fromRoleRaw(roleRaw: UserRoleRaw): RoleDTO {
        return ScopeDTOFactory.roleRawToDTOMappings[roleRaw] || RoleDTO.OPERATOR
    }

    static fromOrganizationRaw(organizationRaw: UserOrganizationRaw): OrganizationDTO {
        return new OrganizationDTO(organizationRaw.name, organizationRaw.slug)
    }
}
