import { PreferencesDTO } from './PreferencesDTO'
import { ScopeDTO } from './ScopeDTO'

export class UserDTO {
    constructor(
        readonly id: string,
        readonly firstName: string,
        readonly lastName: string,
        readonly email: string,
        readonly preferences: PreferencesDTO,
        readonly scopes: ScopeDTO[]
    ) {}
}
