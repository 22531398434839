import { RouteConfig } from 'vue-router'

const MonitorView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "monitor" */ '../containers/MonitorView.vue')
const SummaryView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "monitor" */ '../containers/SummaryView.vue')
const ParametersView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "monitor" */ '../containers/ParametersView.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'summary',
        name: 'MonitorSummary',
        component: SummaryView
    },
    {
        path: 'parameters',
        name: 'MonitorParameters',
        component: ParametersView
    },
    {
        path: '',
        name: 'Monitor',
        redirect: { name: 'MonitorSummary' }
    }
]

export const container = MonitorView
