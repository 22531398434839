import { RouteConfig } from 'vue-router'

const DataView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "data-management" */ '../containers/DataView.vue')
const TelemetryView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "data-management" */ '../containers/TelemetryView.vue')
const DashboardView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "data-management" */ '../containers/DashboardView.vue')
const NetworkView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "data-management" */ '../containers/NetworkView.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'telemetry',
        name: 'DataTelemetry',
        component: TelemetryView,
        redirect: { name: 'Dashboard' },
        children: [
            {
                path: 'dashboards/:dashboardId?',
                name: 'Dashboard',
                component: DashboardView,
                props: true
            }
        ]
    },
    {
        path: 'network',
        name: 'DataNetwork',
        component: NetworkView
    },
    {
        path: '',
        name: 'Data',
        redirect: { name: 'DataTelemetry' }
    }
]

export const container = DataView
