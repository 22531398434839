import { RouteConfig } from 'vue-router'

const DetectView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "detect" */ '../containers/DetectView.vue')
const OverviewView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "detect" */ '../containers/OverviewView.vue')
const EventsView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "detect" */ '../containers/EventsView.vue')
const ParametersView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "detect" */ '../containers/ParametersView.vue')
const NetworkView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "detect" */ '../containers/NetworkView.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'overview/:zoneId?',
        name: 'DetectOverview',
        component: OverviewView,
        props: true
    },
    {
        path: 'events',
        name: 'DetectEvents',
        component: EventsView,
        props: true
    },
    {
        path: 'events/dma/:dmaId?/configuration/:configurationId?/event/:eventId?',
        name: 'DetectEventDetails',
        component: EventsView,
        props: true
    },
    {
        path: 'parameters',
        name: 'DetectParameters',
        component: ParametersView
    },
    {
        path: 'dma/:id?',
        name: 'DetectNetwork',
        component: NetworkView,
        props: true
    },
    {
        path: '',
        name: 'Detect',
        redirect: { name: 'DetectOverview' }
    }
]

export const container = DetectView
