import { UserAPI, UserUpdateDTO } from '@/api/user'
import { User } from '@/domains/common/models'
import { BaseService } from '@/domains/common/services/BaseService'

import { IUserService } from './IUserService'
import { UserFactory } from './UserFactory'
import { Preferences } from '../../models/Preferences'

export class UserService extends BaseService implements IUserService {
    private readonly api: UserAPI = new UserAPI()

    private _user: User | null = null

    public async getIdentity(): Promise<User> {
        if (this._user) {
            return this._user
        }

        const dto = await this.api.getIdentity()
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }
        const user = UserFactory.fromDTO(dto)
        this._user = user
        return user
    }

    public async updateUser(userId: string, preferences: Preferences): Promise<User> {
        const dto = await this.api.updateUser(
            userId,
            new UserUpdateDTO({
                preferences: preferences
            })
        )
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }

        return UserFactory.fromDTO(dto)
    }

    public async updateUserPassword(
        userId: string,
        currentPassword: string,
        newPassword: string,
        confirmPassword: string
    ): Promise<User> {
        const dto = await this.api.updateUser(
            userId,
            new UserUpdateDTO({
                newPassword: newPassword,
                confirmPassword: confirmPassword,
                currentPassword: currentPassword
            })
        )
        if (!dto) {
            throw new Error('user.errors.current_not_found')
        }

        return UserFactory.fromDTO(dto)
    }

    public clear(): void {
        this._user = null
    }
}
