import axios, { AxiosError } from 'axios'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { AppVersionDTO, AppVersionDTOFactory, AppVersionRaw } from './dto'
import { ISettingsAPI } from './ISettingsAPI'

export class SettingsAPI implements ISettingsAPI {
    private routes = {
        getVersion: makeURL('/version/')
    }

    public async retrieveVersion(): Promise<AppVersionDTO | null> {
        let rawVersion = null
        try {
            rawVersion = await axios.get<AppVersionRaw>(this.routes.getVersion)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }

        if (!rawVersion?.data) {
            return null
        }

        return AppVersionDTOFactory.fromRaw(rawVersion.data)
    }
}
