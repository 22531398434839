import { GeoJsonProperties } from 'geojson'
import { AnyLayer, MapLayerEventType } from 'mapbox-gl'

import { MapBoxGL, MapBoxGLEvent } from './MapBoxGL'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseState = { [key: string]: any }

export class BaseController {
    public readonly sources: string[] = []
    public readonly layers: AnyLayer[] = []

    constructor(protected readonly mapBoxGL: MapBoxGL) {}

    public initialize(): void {
        this.initialzeSymbols()
        this.initializeSources()
        this.initializeLayers()
        this.initializeCursors()
        this.initializePopup()
        this.initializeLayerEvents()
    }

    private initialzeSymbols(): void {
        const symbols = this.getSymbols()
        for (const [name, symbol] of symbols) {
            this.mapBoxGL.addSymbol(name, symbol)
        }
    }

    private initializeSources(): void {
        const sources = this.getSources()
        for (const source of sources) {
            this.mapBoxGL.addSource(source)
        }
    }

    private initializeLayers(): void {
        const layers = this.getLayers()
        for (const layer of layers) {
            this.layers.push(layer)
            this.mapBoxGL.addLayer(layer)
        }
    }

    private initializeCursors(): void {
        const cursors = this.getCursors()
        for (const [layerId, cursorId] of cursors) {
            this.mapBoxGL.addCursor(layerId, cursorId)
        }
    }

    private initializePopup(): void {
        const layers = this.getLayersWithPopup()
        for (const layer of layers) {
            this.mapBoxGL.addLayerEventHandler('mousemove', layer.id, (event: MapBoxGLEvent) =>
                this.onMouseMovingOnLayer(event)
            )
            this.mapBoxGL.addLayerEventHandler('mouseleave', layer.id, (event: MapBoxGLEvent) =>
                this.onMouseLeavingLayer(event)
            )
        }
    }

    private initializeLayerEvents(): void {
        const layerEvents = this.getLayerEvents()
        for (const layerEvent of layerEvents) {
            this.mapBoxGL.addLayerEventHandler(layerEvent.name, layerEvent.layer, layerEvent.callback)
        }
    }

    public getSourceName(name: string): string {
        return `${this.mapBoxGL.applicationPrefix}-${name}`
    }

    public onMouseMovingOnLayer(event: MapBoxGLEvent): void {
        if (!event.features) {
            return
        }

        const feature = event.features[0]
        if (!feature) {
            return
        }

        const popupText = this.buildPopupText(feature.properties, feature.state)
        if (!popupText) {
            return
        }

        this.mapBoxGL.showPopup(event.lngLat, popupText)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public buildPopupText(properties: GeoJsonProperties, state: BaseState): string | null {
        return ''
    }

    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    public onMouseLeavingLayer(event: MapBoxGLEvent): void {
        this.mapBoxGL.hidePopup()
    }

    public getSources(): string[] {
        return []
    }

    public getLayers(): AnyLayer[] {
        return []
    }

    public getLayersWithPopup(): AnyLayer[] {
        return []
    }

    public getCursors(): Map<string, string> {
        return new Map()
    }

    public getSymbols(): Map<string, { url: string; sdf: boolean }> {
        return new Map()
    }

    public getLayerEvents(): { name: keyof MapLayerEventType; layer: string; callback: (ev: MapBoxGLEvent) => void }[] {
        return []
    }
}
