import { OrganizationDTO } from './OrganizationDTO'

export enum RoleDTO {
    MASTER_ADMINISTRATOR = 'master-administrator',
    ORGANIZATION_ADMINISTRATOR = 'organization-administrator',
    MANAGER = 'manager',
    OPERATOR = 'operator'
}

export class ScopeDTO {
    constructor(readonly organization: OrganizationDTO, readonly role: RoleDTO) {}
}
