import { FeatureCollection } from 'geojson'

export class ZoneMap {
    private _dmas: FeatureCollection
    private _sensors: FeatureCollection

    constructor(dmas: FeatureCollection, sensors: FeatureCollection) {
        this._dmas = dmas
        this._sensors = sensors
    }

    public get dmas(): FeatureCollection {
        return this._dmas
    }

    public get sensors(): FeatureCollection {
        return this._sensors
    }
}
