import axios, { AxiosError } from 'axios'

import { makeURL } from '../config'
import { ServerError } from '../errors/ServerError'

import {
    PartialUserDTO,
    PartialUserDTOFactory,
    PartialUserRaw,
    UserDTO,
    UserDTOFactory,
    UserRaw,
    UserUpdateDTO,
    UserUpdateRaw
} from './dto'
import { IUserAPI } from './IUserAPI'

export class UserAPI implements IUserAPI {
    private routes = {
        identity: makeURL('/account/users/self/'),
        getUsers: makeURL('/account/users/'),
        updateUser: (userId: string) => makeURL(`/account/users/${userId}/`)
    }

    public async getIdentity(): Promise<UserDTO | null> {
        let rawUser = null
        try {
            rawUser = await axios.get<UserRaw>(this.routes.identity)
        } catch (exception) {
            this.handleError(this.routes.identity, exception as AxiosError<ServerError>)
        }

        if (!rawUser?.data) {
            return null
        }

        return UserDTOFactory.fromRaw(rawUser.data)
    }

    public async getUsers(): Promise<PartialUserDTO[] | null> {
        let rawUsers = null
        try {
            rawUsers = await axios.get<PartialUserRaw[]>(this.routes.getUsers)
        } catch (exception) {
            this.handleError(this.routes.getUsers, exception as AxiosError<ServerError>)
        }

        if (!rawUsers?.data) {
            return null
        }

        return rawUsers.data.map(PartialUserDTOFactory.fromRaw)
    }

    public async updateUser(userId: string, changes: UserUpdateDTO): Promise<UserDTO | null> {
        let rawUser = null
        try {
            const data: Partial<UserUpdateRaw> = UserDTOFactory.fromUpdateDTO(changes)
            rawUser = await axios.patch<UserRaw>(this.routes.updateUser(userId), data)
        } catch (exception) {
            this.handleError(this.routes.identity, exception as AxiosError<ServerError>)
        }

        if (!rawUser?.data) {
            return null
        }

        return UserDTOFactory.fromRaw(rawUser.data)
    }

    handleError(url: string, error: AxiosError<ServerError>): void {
        if (error.request.status === 500) {
            console.error(url)
            console.error(error)
        }
    }
}
