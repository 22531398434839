import { SensorType } from '@/domains/network/models'

export class Sensor {
    private _id: string
    private _name: string
    private _type: SensorType

    constructor(id: string, name: string, type: SensorType) {
        this._id = id
        this._name = name
        this._type = type
    }

    public get id(): string {
        return this._id
    }

    public get name(): string {
        return this._name
    }

    public get type(): SensorType {
        return this._type
    }
}
