import { RawLocation, Route, RouteConfig } from 'vue-router'

const NetworkView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "network" */ '../containers/NetworkView.vue')
const NetworkSharedView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "network" */ '../containers/NetworkSharedView.vue')

export const routes: Array<RouteConfig> = [
    {
        path: ':type/:id?',
        name: 'NetworkShared',
        component: NetworkSharedView,
        props: true
    },
    {
        path: '',
        name: 'Network',
        redirect: (to: Route): RawLocation => {
            return { name: 'NetworkShared', params: { ...to.params, type: 'zone' } }
        }
    }
]

export const container = NetworkView
