export class Preferences {
    private _language: string | null

    constructor(language: string | null) {
        this._language = language
    }

    static default(): Preferences {
        return new Preferences(null)
    }

    public get language(): string | null {
        return this._language
    }
}
