import axios, { CancelTokenSource } from 'axios'
import QueryString from 'qs'

const inCookieBaseUrl = document.cookie
    .split('; ')
    .find((row) => row.startsWith('VUE_APP_BASE_API_URL='))
    ?.split('=')[1]

const baseURL = inCookieBaseUrl === undefined ? process.env.VUE_APP_BASE_API_URL : inCookieBaseUrl

axios.defaults.paramsSerializer = (params) => {
    // Remove square brackets from array query parameters' name
    // Before: http://xxx.com/?param[]=1&param[]=2
    // After : http://xxx.com/?param=1&param=2
    return QueryString.stringify(params, { indices: false })
}

export function makeURL(path: string): string {
    let fullPath = path
    if (!path.startsWith('/')) {
        fullPath = `/${path}`
    }
    return `${baseURL}${fullPath}`
}

export type CancelSource = CancelTokenSource

export const getCancelTokenSource = (): CancelSource => {
    return axios.CancelToken.source()
}
