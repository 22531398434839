import { AuthService, IAuthService } from './auth'
import { CancelToken } from './CancelToken'
import { ServiceLocator } from './ServiceLocator'
import { ISettingsService, SettingsService } from './settings'
import { ITimezoneService, TtimezoneService } from './timezone'
import { ITranslationService, TranslationService } from './translation'
import { IUserService, UserService } from './user'

const serviceLocator = new ServiceLocator()

serviceLocator.add<IAuthService>('auth', new AuthService(serviceLocator))
serviceLocator.add<ITranslationService>('translation', new TranslationService(serviceLocator))
serviceLocator.add<IUserService>('user', new UserService(serviceLocator))
serviceLocator.add<ISettingsService>('settings', new SettingsService(serviceLocator))
serviceLocator.add<ITimezoneService>('timezone', new TtimezoneService(serviceLocator))

export const serviceLocatorInstance = serviceLocator

export { ServiceLocator }
export type { CancelToken, IAuthService, ISettingsService, ITranslationService, ITimezoneService, IUserService }
