export class Organization {
    private _name: string
    private _slug: string

    constructor(name: string, slug: string) {
        this._name = name
        this._slug = slug
    }

    public get name(): string {
        return this._name
    }

    public get slug(): string {
        return this._slug
    }
}