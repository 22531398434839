import { DateTime } from 'luxon'

export class BalanceTimeStep {
    private _timestamp: DateTime
    private _flow: number
    private _missingSensors: string[]

    public constructor(timestamp: DateTime, flow = NaN, missingSensors: string[] = []) {
        this._timestamp = timestamp.toUTC()
        this._flow = flow
        this._missingSensors = missingSensors.filter((ms) => ms.trim().length > 0)
    }

    get timestamp(): DateTime {
        return this._timestamp
    }

    get flow(): number {
        return Math.round(this._flow * 100) / 100
    }

    get hasFlow(): boolean {
        return !Number.isNaN(this.flow)
    }

    get hasMissingSensors(): boolean {
        return this._missingSensors.length > 0
    }

    get missingSensors(): string[] {
        return this._missingSensors
    }
}
