import { DateTime } from 'luxon'

export class EngineConfiguration {
    private _id: string | null
    private _providerType: string
    private _name: string
    private _earliestComputableDate: DateTime | null
    private _active: boolean
    private _settings: { [key: string]: unknown }

    constructor(
        id: string | null,
        providerType: string,
        name: string,
        earliestComputableDate: DateTime | null,
        active: boolean,
        settings: { [key: string]: unknown }
    ) {
        this._id = id
        this._providerType = providerType
        this._name = name
        this._earliestComputableDate = earliestComputableDate
        this._active = active
        this._settings = settings
    }

    public get id(): string | null {
        return this._id
    }

    public get providerType(): string {
        return this._providerType
    }

    public get name(): string {
        return this._name
    }

    public get earliestComputableDate(): DateTime | null {
        return this._earliestComputableDate
    }

    public get active(): boolean {
        return this._active
    }

    public set active(value: boolean) {
        this._active = value
    }

    public get settings(): { [key: string]: unknown } {
        return this._settings
    }
}
