import axios, { AxiosError } from 'axios'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { ITimezoneAPI } from './ITimezoneAPI'

export class TimezoneAPI implements ITimezoneAPI {
    private routes = {
        fetchTimezones: makeURL('localization/timezones/')
    }

    public async fetchTimezones(): Promise<string[]> {
        try {
            const result = await axios.get<string[]>(this.routes.fetchTimezones)
            return result.data
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}
