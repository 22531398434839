import { DateTime, Duration } from 'luxon'

import { BalanceTimeStep } from './BalanceTimeStep'
import { Dma } from './Dma'
import { MissingSensorPeriod } from './MissingSensorsPeriod'
import { Sensor } from './Sensor'

class Aggregate {
    begin: DateTime
    end: DateTime
    missingSensors: string[]

    constructor(begin: DateTime, end: DateTime, missingSensors: string[]) {
        this.begin = begin
        this.end = end
        this.missingSensors = missingSensors
    }
}

export class Balance {
    private _dma: Dma
    private _timesteps: BalanceTimeStep[]

    constructor(dma: Dma, timesteps: BalanceTimeStep[] = []) {
        this._dma = dma
        this._timesteps = timesteps
    }

    public getMissingSensorsPeriods(): MissingSensorPeriod[] {
        const missingSensorSteps = this._timesteps.filter((ts) => ts.hasMissingSensors)

        const rawMissingPeriods = this.aggregateMissingSensorsPeriod(
            missingSensorSteps,
            Duration.fromDurationLike({ minutes: this._dma.timeStep })
        )
        return rawMissingPeriods.map(
            (period) => new MissingSensorPeriod(period.begin, period.end, this.mapIdsToSensors(period.missingSensors))
        )
    }

    private aggregateMissingSensorsPeriod(timesteps: BalanceTimeStep[], dmaTimestep: Duration): Aggregate[] {
        if (timesteps.length == 0) return []

        const halfDmaTimestep: Duration = Duration.fromDurationLike({ minutes: dmaTimestep.as('minutes') / 2 })
        const firstTimestep = timesteps[0]
        const aggregated = [
            new Aggregate(
                firstTimestep.timestamp.minus(halfDmaTimestep),
                firstTimestep.timestamp.plus(halfDmaTimestep),
                firstTimestep.missingSensors
            )
        ]

        for (const timestep of timesteps.slice(1)) {
            const current = aggregated[aggregated.length - 1]
            const currentMissingSensors = current.missingSensors
            const timestepMissingSensors = timestep.missingSensors

            const sameValues =
                currentMissingSensors.length == timestepMissingSensors.length &&
                currentMissingSensors.every((ms) => timestepMissingSensors.includes(ms))
            const samePeriod = current.end.plus(halfDmaTimestep).equals(timestep.timestamp)

            if (sameValues && samePeriod) {
                current.end = timestep.timestamp.plus(halfDmaTimestep)
            } else {
                aggregated.push(
                    new Aggregate(
                        timestep.timestamp.minus(halfDmaTimestep),
                        timestep.timestamp.plus(halfDmaTimestep),
                        timestep.missingSensors
                    )
                )
            }
        }
        return aggregated
    }

    private mapIdsToSensors(ids: string[]): Sensor[] {
        const sensors = ids
            .map((id) => this._dma.getSensorById(id))
            .filter((sensor) => sensor != null)
            .map((sensor) => sensor as Sensor)

        return sensors
    }

    get timesteps(): BalanceTimeStep[] {
        return this._timesteps
    }
}
