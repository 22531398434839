import { defer } from 'lodash'
import Vue, { VNode } from 'vue'

import { RoleEnum } from '../models'
import { IUserService, serviceLocatorInstance } from '../services'

interface ClickOutsideHTMLElement extends HTMLElement {
    clickOutsideEvent: (event: MouseEvent) => void
}

Vue.directive('click-outside', {
    bind(el, binding, vnode) {
        defer(() => {
            const element = el as ClickOutsideHTMLElement
            element.clickOutsideEvent = (event: MouseEvent) => {
                if (
                    !(el === event.target || el.contains(event.target as Node)) &&
                    vnode.context &&
                    binding.expression
                ) {
                    // eslint-disable-next-line
                    ;(vnode.context as any)[binding.expression](event)
                }
            }
            document.body.addEventListener('click', (el as ClickOutsideHTMLElement).clickOutsideEvent)
        })
    },
    unbind(el) {
        document.body.removeEventListener('click', (el as ClickOutsideHTMLElement).clickOutsideEvent)
    }
})

// Role access
Vue.directive('access', async (el, binding, vnode) => {
    const role: RoleEnum | undefined = binding.value

    if (!role) {
        return
    }

    await checkRoleForElement(vnode, el, role)
})

Vue.directive('admin', async (el, binding, vnode) => {
    await checkRoleForElement(vnode, el, RoleEnum.ORGANIZATION_ADMINISTRATOR)
})

Vue.directive('manager', async (el, binding, vnode) => {
    await checkRoleForElement(vnode, el, RoleEnum.MANAGER)
})

Vue.directive('operator', async (el, binding, vnode) => {
    await checkRoleForElement(vnode, el, RoleEnum.OPERATOR)
})

async function checkRoleForElement(vnode: VNode, el: HTMLElement, role: RoleEnum) {
    const organization = vnode.context?.$route.params?.organization || ''
    const userService = serviceLocatorInstance.get<IUserService>('user')
    const user = await userService.getIdentity()

    if (!user.hasRolesInOrganization(organization, role)) {
        el.style.display = 'none'
    }
}
