import {
    BooleanLegendRepresentation,
    RangeLegendRepresentation,
    ValuesLegendRepresentation
} from '@/domains/common/models'
import { DateTime } from 'luxon'

export class DetectKpis {
    private _providerType: string
    private _timestamp: DateTime | null
    private _eventType: string | null
    private _alertLevel: number
    private _lossFlow: number
    private _flowPerHouseConnection: number
    private _flowPerPipeLength: number
    private _lossVolume: number
    private _mnf: number

    constructor(
        providerType: string,
        timestamp: DateTime | null,
        eventType: string | null,
        alertLevel: number,
        lossFlow: number,
        flowPerHouseConnection: number,
        flowPerPipeLength: number,
        lossVolume: number,
        mnf: number
    ) {
        this._providerType = providerType
        this._timestamp = timestamp
        this._eventType = eventType
        this._alertLevel = alertLevel
        this._lossFlow = lossFlow
        this._flowPerHouseConnection = flowPerHouseConnection
        this._flowPerPipeLength = flowPerPipeLength
        this._lossVolume = lossVolume
        this._mnf = mnf
    }

    get providerType(): string {
        return this._providerType
    }

    set providerType(value: string) {
        this._providerType = value
    }

    get timestamp(): DateTime | null {
        return this._timestamp
    }

    get eventType(): string | null {
        return this._eventType
    }

    set eventType(value: string | null) {
        this._eventType = value
    }

    get alertLevel(): number {
        return this._alertLevel
    }

    set alertLevel(value: number) {
        this._alertLevel = value
    }

    get lossFlow(): number {
        return this._lossFlow
    }

    get flowPerHouseConnection(): number {
        return this._flowPerHouseConnection
    }

    get flowPerPipeLength(): number {
        return this._flowPerPipeLength
    }

    get lossVolume(): number {
        return this._lossVolume
    }

    get mnf(): number {
        return this._mnf
    }
}

export class QualityKpis {
    private _providerType: string
    private _timestamp: DateTime | null
    private _positiveNightFlow: boolean | null
    private _missingData: number

    constructor(
        providerType: string,
        timestamp: DateTime | null,
        positiveNightFlow: boolean | null,
        missingData: number
    ) {
        this._providerType = providerType
        this._timestamp = timestamp
        this._positiveNightFlow = positiveNightFlow
        this._missingData = missingData
    }

    get providerType(): string {
        return this._providerType
    }

    get timestamp(): DateTime | null {
        return this._timestamp
    }

    get positiveNightFlow(): boolean | null {
        return this._positiveNightFlow
    }

    get missingData(): number {
        return this._missingData
    }
}

export class Kpis {
    private _detect: DetectKpis | null
    private _quality: QualityKpis | null

    constructor(detect: DetectKpis | null, quality: QualityKpis | null) {
        this._detect = detect
        this._quality = quality
    }

    get detect(): DetectKpis | null {
        return this._detect
    }

    get quality(): QualityKpis | null {
        return this._quality
    }
}

export interface MapKpis {
    alertLevel: ValuesLegendRepresentation
    lossFlow: RangeLegendRepresentation
    flowPerHouseConnection: RangeLegendRepresentation
    flowPerPipeLength: RangeLegendRepresentation
    isPositiveNightFlow: BooleanLegendRepresentation
    missingMeasurementsPercentage: RangeLegendRepresentation
}

export type NetworkKpis = Map<string, Kpis>
