export class BaseDma {
    protected _id: string
    protected _name: string
    protected _description: string
    protected _active: boolean

    constructor(id: string, name: string, description: string, active: boolean) {
        this._id = id
        this._name = name
        this._description = description
        this._active = active
    }

    public get id(): string {
        return this._id
    }

    public get name(): string {
        return this._name
    }

    public get description(): string {
        return this._description
    }

    public get active(): boolean {
        return this._active
    }
}
