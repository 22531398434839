import { RawLocation, RouteConfig } from 'vue-router'

const UsageView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "usage" */ '../containers/UsageView.vue')
const UsageReportView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "usage" */ '../containers/UsageReportView.vue')
const UsageUserView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "usage" */ '../containers/UsageUserView.vue')
const UsageEngineView = (): Promise<typeof import('*.vue')> =>
    import(/* webpackChunkName: "usage" */ '../containers/UsageEngineView.vue')

export const routes: Array<RouteConfig> = [
    {
        path: 'report',
        name: 'UsageReport',
        component: UsageReportView
    },
    {
        path: 'users',
        name: 'UsageUser',
        component: UsageUserView
    },
    {
        path: 'engine',
        name: 'UsageEngine',
        component: UsageEngineView
    },
    {
        path: '',
        name: 'Usage',
        redirect: (): RawLocation => {
            return { name: 'UsageReport' }
        }
    }
]

export const container = UsageView
