import { EngineEventType } from '@/domains/detect/models/enums'
import { DateTime } from 'luxon'

export abstract class BaseEngineEvent {
    private _id: string
    private _dmaId: string
    private _dmaName: string
    private _dmaExternalId: string
    private _configurationId: string
    private _providerType: string
    private _type: EngineEventType
    private _createdOn: DateTime
    private _lastUpdatedOn: DateTime
    private _firstTimestamp: DateTime | null
    private _lastTimestamp: DateTime | null

    constructor(
        id: string,
        dma_id: string,
        dma_name: string,
        dma_external_id: string,
        configuration_id: string,
        provider_type: string,
        type: EngineEventType,
        created_on: DateTime,
        last_updated_on: DateTime,
        first_timestamp: DateTime | null,
        last_timestamp: DateTime | null
    ) {
        this._id = id
        this._dmaId = dma_id
        this._dmaName = dma_name
        this._dmaExternalId = dma_external_id
        this._configurationId = configuration_id
        this._providerType = provider_type
        this._type = type
        this._createdOn = created_on
        this._lastUpdatedOn = last_updated_on
        this._firstTimestamp = first_timestamp
        this._lastTimestamp = last_timestamp
    }

    get id(): string {
        return this._id
    }

    get dmaId(): string {
        return this._dmaId
    }

    get dmaName(): string {
        return this._dmaName
    }

    get dmaExternalId(): string {
        return this._dmaExternalId
    }

    get configurationId(): string {
        return this._configurationId
    }

    get providerType(): string {
        return this._providerType
    }

    get type(): EngineEventType {
        return this._type
    }

    get createdOn(): DateTime {
        return this._createdOn
    }

    get lastUpdatedOn(): DateTime {
        return this._lastUpdatedOn
    }

    get firstTimestamp(): DateTime | null {
        return this._firstTimestamp
    }

    get lastTimestamp(): DateTime | null {
        return this._lastTimestamp
    }
}
