import { BaseEngineEvent, EngineEventType } from '@/domains/detect/models'
import { EngineEventDataPoint } from '@/domains/detect/models'
import { DateTime } from 'luxon'

export class EngineEventDetail extends BaseEngineEvent {
    private _dataPoints: EngineEventDataPoint[]

    constructor(
        id: string,
        dmaId: string,
        dmaName: string,
        dmaExternalId: string,
        configurationId: string,
        providerType: string,
        type: EngineEventType,
        createdOn: DateTime,
        lastUpdatedOn: DateTime,
        dataPoints: EngineEventDataPoint[],
        firstTimestamp: DateTime | null,
        lastTimestamp: DateTime | null
    ) {
        super(
            id,
            dmaId,
            dmaName,
            dmaExternalId,
            configurationId,
            providerType,
            type,
            createdOn,
            lastUpdatedOn,
            firstTimestamp,
            lastTimestamp
        )
        this._dataPoints = dataPoints
    }

    get dataPoints(): EngineEventDataPoint[] {
        return this._dataPoints
    }
}
