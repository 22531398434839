import { getCancelTokenSource } from '@/api/config'

import { CancelToken } from './CancelToken'
import { IService } from './IService'
import { ServiceLocator } from './ServiceLocator'

export abstract class BaseService implements IService {
    constructor(protected readonly serviceLocator: ServiceLocator) {}

    public getCancelToken(): CancelToken {
        return getCancelTokenSource()
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public clear(): void {}
}
