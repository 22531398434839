import { EngineConfiguration } from './EngineConfiguration'

export class Engines {
    private _detect: EngineConfiguration[]
    private _detectOperationalConfigurationId: string | null
    private _monitor: EngineConfiguration[]
    private _monitorOperationalConfigurationId: string | null
    private _quality: EngineConfiguration[]
    private _qualityOperationalConfigurationId: string | null

    constructor(
        detect: EngineConfiguration[],
        detectOperationalConfigurationId: string | null,
        monitor: EngineConfiguration[],
        monitorOperationalConfigurationId: string | null,
        quality: EngineConfiguration[],
        qualityOperationalConfigurationId: string | null
    ) {
        this._detect = detect
        this._detectOperationalConfigurationId = detectOperationalConfigurationId
        this._monitor = monitor
        this._monitorOperationalConfigurationId = monitorOperationalConfigurationId
        this._quality = quality
        this._qualityOperationalConfigurationId = qualityOperationalConfigurationId
    }

    public get detect(): EngineConfiguration[] {
        return this._detect
    }

    public get detectOperationalConfigurationId(): string | null {
        return this._detectOperationalConfigurationId
    }

    public get monitor(): EngineConfiguration[] {
        return this._monitor
    }

    public get monitorOperationalConfigurationId(): string | null {
        return this._monitorOperationalConfigurationId
    }

    public get quality(): EngineConfiguration[] {
        return this._quality
    }

    public get qualityOperationalConfigurationId(): string | null {
        return this._qualityOperationalConfigurationId
    }
}
