import { UserUpdateDTO } from './UserUpdateDTO'
import { UserDTO } from './UserDTO'
import { UserRaw } from './UserRaw'
import { UserUpdateRaw } from './UserUpdateRaw'
import { ScopeDTOFactory } from '@/api/user/dto/ScopeDTOFactory'

export abstract class UserDTOFactory {
    static fromRaw(userRaw: UserRaw): UserDTO {
        return new UserDTO(
            userRaw.id,
            userRaw.first_name,
            userRaw.last_name,
            userRaw.email,
            userRaw.preferences,
            userRaw.scopes ? userRaw.scopes.map(ScopeDTOFactory.fromRaw) : []
        )
    }

    static fromUpdateDTO(userUpdateDto: UserUpdateDTO): Partial<UserUpdateRaw> {
        return {
            username: userUpdateDto.username,
            first_name: userUpdateDto.firstName,
            last_name: userUpdateDto.lastName,
            email: userUpdateDto.email,
            preferences: {
                language: userUpdateDto.preferences?.language || undefined
            },
            password: userUpdateDto.newPassword,
            confirm_password: userUpdateDto.confirmPassword,
            old_password: userUpdateDto.currentPassword
        }
    }
}
