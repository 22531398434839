import { DateTime } from 'luxon'
import { EngineEventType } from './enums'
import { BaseEngineEvent } from './BaseEngineEvent'
import { EngineEventDataPoint } from './EngineEventDataPoint'

export class EngineEvent extends BaseEngineEvent {
    private _lastInfo: EngineEventDataPoint | null

    constructor(
        id: string,
        dmaId: string,
        dmaName: string,
        dmaExternalId: string,
        configurationId: string,
        providerType: string,
        type: EngineEventType,
        createdOn: DateTime,
        lastUpdatedOn: DateTime,
        lastInfo: EngineEventDataPoint | null,
        firstTimestamp: DateTime | null,
        lastTimestamp: DateTime | null
    ) {
        super(
            id,
            dmaId,
            dmaName,
            dmaExternalId,
            configurationId,
            providerType,
            type,
            createdOn,
            lastUpdatedOn,
            firstTimestamp,
            lastTimestamp
        )
        this._lastInfo = lastInfo
    }

    get lastInfo(): EngineEventDataPoint | null {
        return this._lastInfo
    }
}
