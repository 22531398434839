import { ITimezoneAPI, TimezoneAPI } from '@/api/timezone'

import { BaseService } from '../BaseService'
import { ITimezoneService } from './ITimezoneService'

export class TtimezoneService extends BaseService implements ITimezoneService {
    private readonly api: ITimezoneAPI = new TimezoneAPI()

    private _timezonesCached: string[] | undefined

    public async getTimezones(): Promise<string[]> {
        if (this._timezonesCached) {
            return this._timezonesCached
        }

        const dto = await this.api.fetchTimezones()
        if (!dto) {
            throw new Error('timezones.errors.not_found')
        }

        this._timezonesCached = dto
        return dto
    }
}
