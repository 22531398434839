import { ConnectionDirectionEnum } from './enums'

export class Connection<T> {
    private _connected: T
    private _direction: ConnectionDirectionEnum

    constructor(connected: T, direction: ConnectionDirectionEnum) {
        this._connected = connected
        this._direction = direction
    }

    public get connected(): T {
        return this._connected
    }

    public get direction(): ConnectionDirectionEnum {
        return this._direction
    }
}
