import { RoleEnum } from './enums'
import { Organization } from './Organization'
import { Preferences } from './Preferences'
import { Scope } from './Scope'

export class User {
    private _id: string
    private _firstName: string
    private _lastName: string
    private _email: string
    private _preferences: Preferences
    private _scopes: Scope[]

    constructor(
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        preferences: Preferences,
        scopes: Scope[]
    ) {
        this._id = id
        this._firstName = firstName
        this._lastName = lastName
        this._email = email
        this._preferences = preferences
        this._scopes = scopes
    }

    public get id(): string {
        return this._id
    }

    public get firstName(): string {
        return this._firstName
    }

    public get lastName(): string {
        return this._lastName
    }

    public get email(): string {
        return this._email
    }

    public get preferences(): Preferences {
        return this._preferences
    }

    public get scopes(): Scope[] {
        return this._scopes
    }

    public get firstOrganization(): Organization | null {
        return this._scopes.length > 0 ? this._scopes[0].organization : null
    }

    public hasRolesInOrganization(organization: string, role: RoleEnum): boolean {
        const scope = this.scopes.find((s) => s.organization.slug === organization)
        if (!scope) {
            return false
        }

        switch (role) {
            case RoleEnum.MASTER_ADMINISTRATOR:
                return scope.role === RoleEnum.MASTER_ADMINISTRATOR

            case RoleEnum.ORGANIZATION_ADMINISTRATOR:
                return (
                    scope.role === RoleEnum.MASTER_ADMINISTRATOR || scope.role === RoleEnum.ORGANIZATION_ADMINISTRATOR
                )

            case RoleEnum.MANAGER:
                return (
                    scope.role === RoleEnum.MASTER_ADMINISTRATOR ||
                    scope.role === RoleEnum.ORGANIZATION_ADMINISTRATOR ||
                    scope.role === RoleEnum.MANAGER
                )

            case RoleEnum.OPERATOR:
                return (
                    scope.role === RoleEnum.MASTER_ADMINISTRATOR ||
                    scope.role === RoleEnum.ORGANIZATION_ADMINISTRATOR ||
                    scope.role === RoleEnum.MANAGER ||
                    scope.role === RoleEnum.OPERATOR
                )
        }
    }

    public isMasterAdministrator(): boolean {
        return this.scopes.find((s) => s.role === RoleEnum.MASTER_ADMINISTRATOR) !== undefined
    }

    public isOrganizationAdministrator(organization: string): boolean {
        return this.hasRolesInOrganization(organization, RoleEnum.ORGANIZATION_ADMINISTRATOR)
    }

    public isManager(organization: string): boolean {
        return this.hasRolesInOrganization(organization, RoleEnum.MANAGER)
    }
}
