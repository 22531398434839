import { OrganizationDTO, RoleDTO, ScopeDTO, UserDTO } from '@/api/user'
import { Organization, RoleEnum, Scope, User } from '@/domains/common/models'
import { Preferences } from '../../models/Preferences'
import { PreferencesDTO } from '@/api/user/dto/PreferencesDTO'

export abstract class UserFactory {
    private static roleDTOToModelMappings: Record<RoleDTO, RoleEnum> = {
        [RoleDTO.MASTER_ADMINISTRATOR]: RoleEnum.MASTER_ADMINISTRATOR,
        [RoleDTO.ORGANIZATION_ADMINISTRATOR]: RoleEnum.ORGANIZATION_ADMINISTRATOR,
        [RoleDTO.MANAGER]: RoleEnum.MANAGER,
        [RoleDTO.OPERATOR]: RoleEnum.OPERATOR
    }

    static fromDTO(dto: UserDTO): User {
        return new User(
            dto.id || '',
            dto.firstName || '',
            dto.lastName || '',
            dto.email || '',
            dto.preferences ? this.fromPreferencesDTO(dto.preferences) : Preferences.default(),
            dto.scopes ? dto.scopes.map(UserFactory.fromScopeDTO) : []
        )
    }

    static fromPreferencesDTO(dto: PreferencesDTO): Preferences {
        return new Preferences(dto.language)
    }

    static fromScopeDTO(dto: ScopeDTO): Scope {
        return new Scope(
            UserFactory.fromOrganizationDTO(dto.organization),
            UserFactory.roleDTOToModelMappings[dto.role] || RoleEnum.OPERATOR
        )
    }

    static fromOrganizationDTO(dto: OrganizationDTO): Organization {
        return new Organization(dto.name, dto.slug)
    }
}
