import { LanguageDTO } from './LanguageDTO'
import { LanguageRaw } from './LanguageRaw'

export abstract class LanguageDTOFactory {
    static fromRaw(raw: LanguageRaw): LanguageDTO {
        return new LanguageDTO(
            raw.code,
            raw.name
        )
    }
}
