import axios, { AxiosError } from 'axios'

import { makeURL } from '@/api/config'
import { ApiError } from '@/api/errors/ApiError'
import { ServerError } from '@/api/errors/ServerError'

import { LanguageDTO, LanguageDTOFactory, LanguageRaw, TranslationRaw } from './dto'
import { ITranslationAPI } from './ITranslationAPI'

export class TranslationAPI implements ITranslationAPI {
    private routes = {
        fetchLanguages: makeURL('localization/languages/'),
        fetchLanguage: (code: string) => makeURL(`localization/translations/${code}/`)
    }

    public async fetchLanguages(): Promise<LanguageDTO[]> {
        try {
            const result = await axios.get<LanguageRaw[]>(this.routes.fetchLanguages)
            return result.data.map(LanguageDTOFactory.fromRaw)
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }

    public async fetchLanguage(code: string): Promise<{ [key: string]: string }> {
        try {
            const url = this.routes.fetchLanguage(code)
            const result = await axios.get<TranslationRaw>(url)
            return result.data.translations
        } catch (e) {
            const error = e as AxiosError<ServerError>
            throw ApiError.from(error.response?.data)
        }
    }
}
