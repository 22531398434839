import { DateTime } from 'luxon'
import { Sensor } from './Sensor'

export class MissingSensorPeriod {
    private _start: DateTime
    private _end: DateTime
    private _sensors: Sensor[]

    constructor(start: DateTime, end: DateTime, sensors: Sensor[]) {
        this._start = start
        this._end = end
        this._sensors = sensors
    }

    get start(): DateTime {
        return this._start
    }

    get end(): DateTime {
        return this._end
    }

    get sensors(): Sensor[] {
        return this._sensors
    }
}
