import { GeoJsonProperties } from 'geojson'
import { DateTime } from 'luxon'
import { isNil } from 'lodash'
import VueI18n from 'vue-i18n'

import { NO_ENGINE } from '@/domains/common/components/map/group/GroupMap.vue'
import { DMAFeatureState } from '@/domains/common/components/map/group/GroupMapController'
import { ITranslationService, serviceLocatorInstance } from '@/domains/common/services'
import { roundNumber } from '@/domains/common/utils/converters'
import { badgeColorScheme } from '@/domains/common/utils/legends-colors'
import { MapKpis } from '@/domains/detect/models'

export function buildDmaPopup(
    properties: GeoJsonProperties,
    state: DMAFeatureState,
    kpisLegends: MapKpis,
    eventTypes: { [EngineEventType: string]: string }
): string | null {
    if (!properties || !state) {
        return null
    }

    const vuePlugin = serviceLocatorInstance.get<ITranslationService>('translation').getVuePlugin()

    const dmaName = properties['name']
    const dmaTitle = `<strong class="is-size-6 mb-2">${dmaName}</strong>`

    const detectSection = buildDetectEngineSection(vuePlugin, kpisLegends, eventTypes, state)
    const qualitySection = buildQualityEngineSection(vuePlugin, kpisLegends, state)

    const layout = `
        ${dmaTitle}
        ${detectSection}
        ${qualitySection}
    `

    return layout
}

function buildDetectEngineSection(
    vuePlugin: VueI18n,
    kpisLegends: MapKpis,
    eventTypes: { [EngineEventType: string]: string },
    state: DMAFeatureState
): string {
    const kpiSelected = state['kpiSelected']

    //////////////////////////////////////////////
    // 1. Retrieve information of DMA last result
    //////////////////////////////////////////////

    const detectProviderType = state['detectProviderType']
    const detectTimestamp = state['detectTimestamp']
    const eventType = state['eventType']
    const alertLevel = state['alertLevel']
    const noAlert = state['noAlert']
    const lossFlow = state['lossFlow']
    const flowPerHouseConnection = state['flowPerHouseConnection']
    const flowPerPipeLength = state['flowPerPipeLength']

    ////////////////////////////
    // 2. Format data to display
    ////////////////////////////

    const formattedDetectTimestamp = formatTimestamp(detectTimestamp)
    const relativeDetectTimestamp = getRelativeTimestamp(vuePlugin, detectTimestamp)

    const alertLevelLabel = vuePlugin.t('model.kpi.alertLevel')
    const eventTypeLabel =
        eventType === NO_ENGINE || eventType == null || !(eventType in eventTypes)
            ? alertLevelLabel
            : eventTypes[eventType]

    //////////////////////////////
    // 3. Retrieve color of labels
    //////////////////////////////

    const defaultColor = '#aaaaaa'
    const alertLevelColor = kpisLegends['alertLevel'].getColorForValue(alertLevel) ?? defaultColor
    const lossFlowColor = kpisLegends['lossFlow'].getColorForValue(lossFlow) ?? defaultColor
    const flowPerHouseConnectionColor =
        kpisLegends['flowPerHouseConnection'].getColorForValue(flowPerHouseConnection) ?? defaultColor
    const flowPerPipeLengthColor = kpisLegends['flowPerPipeLength'].getColorForValue(flowPerPipeLength) ?? defaultColor

    //////////////////////////
    // 4. Build detect section
    //////////////////////////

    // if there is no detect engine, only a title is displayed in the detect section
    const hasDetectEngine = detectProviderType !== NO_ENGINE
    const detectEngineLabel = vuePlugin.t('model.engine.detect')

    const detectSectionTitle = hasDetectEngine
        ? `${detectEngineLabel} - ${detectProviderType} - ${formattedDetectTimestamp} <em>${relativeDetectTimestamp}</em>`
        : `${detectEngineLabel} - ${vuePlugin.t('model.kpi.noDetectEngine')}`

    const detectSectionContent = hasDetectEngine
        ? `<ul>
            <li class="${kpiSelected === 'alertLevel' ? 'has-text-weight-bold' : ''}">
                ${eventTypeLabel}:
                <span style="${getTagStyle(alertLevelColor)}"> ${formatAlertLevel(
              vuePlugin,
              alertLevel,
              noAlert
          )} </span>
            </li>
            <li class="${kpiSelected === 'lossFlow' ? 'has-text-weight-bold' : ''}">
                ${vuePlugin.t('model.kpi.lossFlow')}:
                <span style="${getTagStyle(lossFlowColor)}">
                    ${formatLossFlow(lossFlow)}
                </span>
            </li>
            <li class="${kpiSelected === 'flowPerHouseConnection' ? 'has-text-weight-bold' : ''}">
                ${vuePlugin.t('model.kpi.current_real_loss.flow_connection')}:
                <span style="${getTagStyle(flowPerHouseConnectionColor)}">
                    ${formatLossFlowHouseConnections(vuePlugin, flowPerHouseConnection)}
                </span>
            </li>
            <li class="${kpiSelected === 'flowPerPipeLength' ? 'has-text-weight-bold' : ''}">
                ${vuePlugin.t('model.kpi.current_real_loss.flow_pipe_length')}:
                <span style="${getTagStyle(flowPerPipeLengthColor)}">
                    ${formatLossFlowPipeLength(vuePlugin, flowPerPipeLength)}
                </span>
            </li>
        </ul>`
        : ''

    return `
        <p class="mt-2 is-underlined">${detectSectionTitle}</p>
        ${detectSectionContent}
    `
}

function buildQualityEngineSection(vuePlugin: VueI18n, kpisLegends: MapKpis, state: DMAFeatureState): string {
    const kpiSelected = state['kpiSelected']

    //////////////////////////////////////////////
    // 1. Retrieve information of DMA last result
    //////////////////////////////////////////////

    const qualityProviderType = state['qualityProviderType']
    const qualityTimestamp = state['qualityTimestamp']
    const isPositiveNightFlow = state['isPositiveNightFlow']
    const missingMeasurementsPercentage = state['missingMeasurementsPercentage']

    /////////////////////////////
    // 2. Format data to display
    /////////////////////////////

    const formattedQualityTimestamp = formatTimestamp(qualityTimestamp)
    const relativeQualityTimestamp = getRelativeTimestamp(vuePlugin, qualityTimestamp)

    ///////////////////////////////
    // 3. Retrieve color of labels
    ///////////////////////////////

    const defaultColor = '#aaaaaa'
    const isPositiveNightFlowColor =
        kpisLegends['isPositiveNightFlow'].getColorForValue(isPositiveNightFlow) ?? defaultColor
    const missingMeasurementsPercentageColor =
        kpisLegends['missingMeasurementsPercentage'].getColorForValue(missingMeasurementsPercentage) ?? defaultColor

    ////////////////////////////
    // 4. Build quality section
    ////////////////////////////

    // if there is no quality engine, nothing is displayed in the quality section
    const hasQualityEngine = qualityProviderType !== NO_ENGINE
    if (!hasQualityEngine) {
        return ''
    }

    const qualityEngineLabel = vuePlugin.t('model.engine.quality')
    const qualitySectionTitle = `${qualityEngineLabel} - ${qualityProviderType} - ${formattedQualityTimestamp} <em>${relativeQualityTimestamp}</em>`

    const qualitySectionContent = `
        <ul>
            <li class="${kpiSelected === 'isPositiveNightFlow' ? 'has-text-weight-bold' : ''}">
                ${vuePlugin.t('model.kpi.positiveNightFlow')}:
                <span style="${getTagStyle(isPositiveNightFlowColor)}">
                    ${formatIsPositiveNightFlow(vuePlugin, isPositiveNightFlow)}
                </span>
            </li>
            <li class="${state.kpiSelected === 'missingMeasurementsPercentage' ? 'has-text-weight-bold' : ''}">
                ${vuePlugin.t('model.kpi.missingMeasurementsPercentage')}:
                <span style="${getTagStyle(missingMeasurementsPercentageColor)}">
                    ${formatMissingMeasurementsPercentage(missingMeasurementsPercentage)}
                </span>
            </li>
        </ul>`

    return `
        <p class="mt-2 is-underlined">${qualitySectionTitle}</p>
        ${qualitySectionContent}
    `
}

// Detect and quality sections functions

function format(value: any, formatFunction: (v: any) => string): string {
    if (isNil(value) || value === NO_ENGINE) {
        return '-'
    } else {
        return formatFunction(value)
    }
}

function formatTimestamp(timestamp: DateTime | 'NO_ENGINE' | null): string {
    return format(timestamp, (v) => v.toFormat('dd-MM-yyyy HH:mm'))
}

function getRelativeTimestamp(vuePlugin: VueI18n, timestamp: DateTime | string): string {
    let relativeTimestamp: string | null = null
    if (timestamp instanceof DateTime) {
        relativeTimestamp = timestamp.toRelative({ locale: vuePlugin.locale })
    }
    return relativeTimestamp ? `(${relativeTimestamp})` : ''
}

function getTagStyle(color: string): string {
    return `background-color: ${color}; color: ${
        badgeColorScheme[color] ?? '#000000'
    }; padding: 2px 4px; text-align: center; border-radius: 5px;`
}

// Detect section functions

function formatAlertLevel(vuePlugin: VueI18n, alertLevel: number | 'NO_ENGINE' | null, noAlert: boolean): string {
    return format(alertLevel, (v: number) => {
        if (noAlert) {
            return vuePlugin.t('model.kpi.noAlert') as string
        } else if (v >= 0) {
            return vuePlugin.t('model.kpi.level', { number: v }) as string
        } else {
            return vuePlugin.t('model.kpi.dataIssue') as string
        }
    })
}

function formatLossFlow(lossFlow: number | 'NO_ENGINE' | null): string {
    return `${format(lossFlow, (v) => `${roundNumber(v)}`)} m³/h`
}

function formatLossFlowHouseConnections(vuePlugin: VueI18n, lossFlow: number | 'NO_ENGINE' | null): string {
    return `${format(lossFlow, (v) => `${roundNumber(v)}`)} ${vuePlugin.t(
        'model.kpi.current_real_loss.flow_connection.unit'
    )}`
}

function formatLossFlowPipeLength(vuePlugin: VueI18n, lossFlow: number | 'NO_ENGINE' | null): string {
    return `${format(lossFlow, (v) => `${roundNumber(v)}`)} ${vuePlugin.t(
        'model.kpi.current_real_loss.flow_pipe_length.unit'
    )}`
}

// Quality section functions

function formatIsPositiveNightFlow(vuePlugin: VueI18n, isPositiveNightFlow: boolean | 'NO_ENGINE' | null): string {
    return format(isPositiveNightFlow, (v) =>
        v ? (vuePlugin.t('common.yes') as string) : (vuePlugin.t('common.no') as string)
    )
}

function formatMissingMeasurementsPercentage(value: number | 'NO_ENGINE' | null): string {
    return `${format(value, (v) => `${v}`)} %`
}
