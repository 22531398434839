export class AppVersion {
    private _version: string
    private _build: string

    constructor(version: string, build: string) {
        this._version = version
        this._build = build
    }

    public get version(): string {
        return this._version
    }

    public get build(): string {
        return this._build
    }
}
