
import { Vue, Component, Ref } from 'vue-property-decorator'

import { BaseController } from './BaseController'
import { MapBoxGL } from './MapBoxGL'
import { getEnv } from "@/utils/env"

const APPLICATION_PREFIX = 'hydroscan_water_portal'

@Component
export default class BaseMapComponent<T extends BaseController = BaseController> extends Vue {
    @Ref() readonly mapElement!: HTMLElement

    protected initialCenter: [number, number] = getEnv("VUE_APP_MAPBOX_INITIAL_CENTER")
        ? JSON.parse(getEnv("VUE_APP_MAPBOX_INITIAL_CENTER"))
        : [0, 0]
    protected controller?: T
    protected mapBoxGL?: MapBoxGL

    constructor() {
        super()
    }

    protected getController(mapBoxGL: MapBoxGL): T {
        return new BaseController(mapBoxGL) as T
    }

    mounted(): void {
        const accessToken = getEnv("VUE_APP_MAPBOX_ACCESS_TOKEN")
        const styleUrl = getEnv("VUE_APP_MAPBOX_STYLE_URL")

        this.mapBoxGL = new MapBoxGL(
            APPLICATION_PREFIX,
            this.mapElement,
            accessToken,
            this.initialCenter,
            styleUrl,
            this.onMapLoaded
        )
    }

    protected onMapLoaded(): void {
        this.initMap()
    }

    protected initMap(): void {
        if (!this.mapBoxGL) {
            return
        }

        this.controller = this.getController(this.mapBoxGL)
        this.controller.initialize()

        this.updateMap()
    }

    protected updateMap(): void {
        return
    }
}
