import { LanguageDTO } from '@/api/translation'
import { Language } from '@/domains/common/models'

export abstract class LanguageFactory {
    static fromDTO(dto: LanguageDTO): Language {
        return new Language(
            dto.code,
            dto.name
        )
    }
}