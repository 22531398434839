import { NavigationGuard, NavigationGuardNext, Route } from 'vue-router'
import { IAuthService, IUserService, serviceLocatorInstance } from '@/domains/common/services'

const authenticationGuard: NavigationGuard = async (to: Route, from: Route, next: NavigationGuardNext) => {
    const authService = serviceLocatorInstance.get<IAuthService>('auth')
    if (to.matched.some((record) => record.meta.requiresAuthenticated) && !(await authService.isAuthenticated())) {
        next({
            name: 'Auth',
            params: {
                to: to.fullPath
            }
        })
        return
    }

    if (to.matched.some((record) => record.meta.requiresRole)) {
        const userService = serviceLocatorInstance.get<IUserService>('user')
        const user = await userService.getIdentity()

        if (
            to.matched.some(
                (record) =>
                    record.meta.requiresRole &&
                    !user.hasRolesInOrganization(to.params.organization, record.meta.requiresRole)
            )
        ) {
            next({
                name: 'Auth',
                params: {
                    to: to.fullPath
                }
            })
            return
        }
    }

    next()
}

export default authenticationGuard
